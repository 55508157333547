import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
<path d="M620 1399 c0 -110 0 -111 23 -106 50 13 50 21 42 -432 l-8 -421 -28
0 -29 0 0 -105 0 -105 185 0 185 0 0 105 0 105 -27 6 c-16 3 -34 7 -40 10 -10
2 -13 45 -13 168 0 140 2 165 16 170 9 3 98 6 199 6 145 0 185 -3 194 -14 12
-14 15 -294 4 -323 -3 -7 -20 -13 -39 -13 l-34 0 0 -110 0 -110 185 0 185 0 0
105 0 106 -30 6 -30 6 2 416 3 416 38 3 37 3 0 94 c0 51 -3 100 -6 109 -5 14
-32 16 -190 16 l-184 0 0 -110 0 -110 35 0 35 0 0 -152 0 -151 -127 6 c-71 4
-163 7 -205 7 l-78 0 0 145 0 145 34 0 c31 0 34 3 40 38 3 20 6 67 6 103 0 52
-4 68 -16 73 -9 3 -94 6 -190 6 l-174 0 0 -111z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
